<template>
  <div class="modal is-large is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Agregar Caracteristica</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Característica</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model="characteristics"
                placeholder="Nombre de la característica"
                name="characteristics"
                :class="{ 'is-danger': vverrors.first('characteristics') }"
                v-validate="'required'"
              >
            </div>
          </div>

          <div class="field">
            <label class="label">Categoría</label>
            <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('category_id') }">
              <select
                class="select"
                v-model="category_id"
                v-validate="''"
                name="category_id"
              >
                <option :value="null">Ninguna</option>
                <template v-for="(category, index) of catalogCategoriesList">
                  <option :value="category.id" :key="index">{{category.name}}</option>
                </template>
              </select>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
            <div class="control">
              <button class="button is-link is-light" @click="close()">Cancelar</button>
            </div>
            <div class="control">
              <button class="button button-givu" @click="addCharacteristics()">Guardar</button>
            </div>
          </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function
    },
    catalogCategoriesList: {
      type: Array
    },
    saveCharacteristic: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      characteristics: null,
      category_id: null
    }
  },
  methods: {
    async addCharacteristics () {
      let data = {
        name: this.characteristics,
        categoryId: this.category_id
      }
      if (await this.$validator.validateAll()) {
        this.saveCharacteristic(data)
      }
    }
  }
}
</script>
